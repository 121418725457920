.tos-container {
    font-size: 16pt;
    text-align: left;
}

.nav_tos {
    text-decoration: underline;
    color: black;
    font-size: 12pt;
}

.tos-container p {
    margin-top: 2rem;
    color: var(--primary-grey);
}

@media (min-width: 101px) and (max-width:600px) {

    .tos-container p {
        margin-top: 5rem;
        font-size: 4rem;
    }
}