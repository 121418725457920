@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Base styles */

:root {
    --primary-orange: #D7664A;
    --secondary-orange: #D7661D;
    --primary-blue: #333399;
    --primary-grey: #5E5E5E;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: 'Roboto', sans-serif;
}

.default-settings {
    padding: 10rem 12rem;
}

.page-settings {
    padding: 3rem 12rem;
}

.title-styles {
    font-size: 5rem;
    font-weight: 600;
    color: var(--primary-yellow);
    text-align: center;
    text-transform: capitalize;
    /* margin-bottom: 3rem; */
}

.gradient-bg {
    background-image: linear-gradient(var(--gradient-blue-light), var(--gradient-blue-dark));
}

a {
    text-decoration: none;
}


/* Grid */

.row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.col-1 {
    width: 8.3333%;
}

.col-2 {
    width: 100%;
    max-width: 16.66667%;
    flex: 0 0 16.66667%;
    -webkit-box-flex: 0;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33333%;
}

.col-5 {
    width: 41.66667%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33333%;
}

.col-8 {
    width: 66.66667%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33333%;
}

.col-11 {
    width: 91.66667%;
}

.col-12 {
    width: 100%;
}


/* Display */

.d-block {
    display: block;
}

.d-grid {
    display: grid;
}

.d-none {
    display: none;
}
@media (max-width: 800px) {
    /* html {
      font-size: 31.25%;
    } */
    .default-settings {
      padding: 5rem 4rem;
    }
    .page-settings {
      padding: 5rem 4rem;
      background-image: linear-gradient(180deg, #E8EBF8 0%, #FFFFFF 100%);
    }
    .title-styles-nocaptial {
      font-size: 7rem;
    }
  }
  
  @media (min-width: 701px) and (max-width:800px) {
    html {
      font-size: 35% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 601px) and (max-width:700px) {
    html {
      font-size: 33% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 501px) and (max-width:600px) {
    html {
      font-size: 32% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 401px) and (max-width:500px) {
    html {
      font-size: 29% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 301px) and (max-width:400px) {
    html {
      font-size: 26% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 201px) and (max-width:300px) {
    html {
      font-size: 23% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 101px) and (max-width:200px) {
    html {
      font-size: 15% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 801px) and (max-width: 900px) {
    html {
      font-size: 37% !important;
      -webkit-text-size-adjust:none;
    }
  }
  
  @media (min-width: 901px) and (max-width: 1000px) {
    html {
      font-size: 40% !important;
    }
  }
  
  @media (min-width: 1001px) and (max-width: 1100px) {
    html {
      font-size: 45% !important;
    }
  }
  
  @media not all and (min-resolution: 0.001dpcm) {
    @media (min-width: 401px) and (max-width:500px) {
      html {
        font-size: 30%;
      }
    }
  }
  
  @media (min-width: 1101px) and (max-width: 1200px) {
    html {
      font-size: 50% !important;
    }
  }
  
  @media (min-width: 1201px) and (max-width: 1366px) {
    html {
      font-size: 53% !important;
    }
  }
  
  @media (min-width: 1500px) and (max-width: 1600px) {
    html {
      font-size: 65% !important;
    }
  }
  
  @media (min-width: 1601px) and (max-width: 1700px) {
    html {
      font-size: 70% !important;
    }
  }
  
  @media (min-width: 1701px) and (max-width: 1800px) {
    html {
      font-size: 75% !important;
    }
  }
  
  @media (min-width: 1801px) and (max-width: 1900px) {
    html {
      font-size: 80% !important;
    }
  }
  
  @media (min-width: 1901px) and (max-width: 2000px) {
    html {
      font-size: 85% !important;
    }
  }
  
  @media (min-width: 2001px) and (max-width: 2100px) {
    html {
      font-size: 90% !important;
    }
  }
  
  @media (min-width: 2101px) and (max-width: 2200px) {
    html {
      font-size: 95% !important;
    }
  }
  
  @media (min-width: 2201px) {
    html {
      font-size: 100% !important;
    }
  }
  