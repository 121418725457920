#hero1 {
    height: 85vh;
    background-image: url("../images/bg1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

#hero2 {
    /* height: 65rem; */
    height: 85vh;
    background-color: #EFE51D;
    background-image: url("../images/CandyShop.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -15rem;
    background-size: 90rem;
    position: relative;
}

#hero4 {
    /* height: 65rem; */
    height: 85vh;
    background-image: url("../images/earthquake_ds.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 43%;
    position: relative;
}

#hero3 {
    height: 85vh;
    background-color: #96FCC9;
    position: relative;
}

.more-content-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, 0);
    transition-delay: 10s;
}

.more-content-wrapper-hidden {
    visibility: hidden;
}

.arrow-down {
    height: 1.5rem;
    margin-bottom: 1rem;
}

.more-text {
    font-size: 2rem;
    font-weight: 500;
    color: var(--primary-orange);
}

.white-text {
    color: white;
}


.banner-img {
    height: 80rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: hard-light;
}

.hero-img {
    height: 65rem;
    margin-top: -10rem;
}

.hero-title {
    font-size: 3.5rem;
    font-weight: 600;
    font-style: italic;
    position: absolute;
    left: 2%;
    bottom: 4%;
    text-align: left;
    cursor: pointer;
}

.text-wrap {
    position: relative;
}

.arrow {
    position: absolute;
    width: 7rem;
    height: 3.5px;
    border-radius: 10px;
    top: 60%;
    left: 98%;
}

.arrow::before,
.arrow::after {
    content: '';
    position: absolute;
    width: 1.6rem;
    height: 3px;
    right: -5px;
    border-radius: 10px;
}

.arrow::after {
    top: -5px;
    transform: rotate(50deg);
}

.arrow::before {
    top: 6px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(-50deg);
}

#hero1 .hero-title {
    color: var(--primary-blue);
}

#hero2 .hero-title {
    color: var(--primary-orange);
}

#hero3 .hero-title {
    color: var(--primary-blue);
}

#hero4 .hero-title {
    color: #fff;
}

#hero1 .arrow,
#hero1 .arrow::before,
#hero1 .arrow::after {
    background-color: var(--primary-blue);
}

#hero2 .arrow,
#hero2 .arrow::before,
#hero2 .arrow::after {
    background-color: var(--primary-orange);
}

#hero3 .arrow,
#hero3 .arrow::before,
#hero3 .arrow::after {
    background-color: var(--primary-blue);
}

#hero1 .arrow {
    left: 90%;
}

#hero3 .arrow {
    left: 85%;
}

/* #hero3 .tooltip {
    position: absolute;
    left: 2%;
    bottom: 4%;
    text-align: left;
} */

#hero3 .tooltiptext {
    font-size: 1.2rem;
    top: -40%;
    left: 50%;
    transform: translate(-50%, 0);
    font-style: normal;
}

#hero3 .tooltip .tooltiptext::after {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    border-color: black transparent transparent transparent;
}

#fund {
    padding: 7rem 0rem 0;
}

.fund-title {
    font-size: 6rem;
    font-weight: 600;
    color: var(--primary-grey);
    text-align: center;
    margin-bottom: 6rem;
    margin-top: 2rem;
}

.nft-collection {
    display: flex;
    gap: 12rem;
    justify-content: space-between;
    width: 70%;
    margin: auto;
    padding: 7rem 0;
    /* align-items: center; */
}

.catdrop-collection {
    background-color: #96FCC940;
    /* margin-top: -3.5rem;
    padding-top: 13.5rem;
    height: 40rem; */
}

.earthquake-collection {
    background-color: #C5C5C540;
    /* margin-bottom: 15rem;
    height: 39rem;
    padding-top: 10rem; */
}

.nft-collection__img {
    width: 28%;
}

/* .earthquake-collection .nft-collection__img {
    height: 32rem;
    object-fit: cover;
} */

/* .catdrop-img {
    height: 32rem;
} */

.nft-collection__info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nft-collection__title {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.octopi-title {
    color: var(--primary-orange);
}

.earthquake-title {
    color: #504c4c;
}

.catdrop-title {
    color: var(--primary-blue);
}

.nft-collection__desc {
    font-size: 2.7rem;
    font-weight: 400;
    color: var(--primary-grey);
    max-width: 60rem;
    line-height: 1.3;
}

.nft-collection__btn {
    color: white;
    display: inline-block;
    width: 30rem;
    font-size: 2.5rem;
    font-weight: 500;
    padding: 1rem 3rem;
    border-radius: 30px;
    cursor: pointer;
    /* margin-bottom: 1rem; */
}

.octopi-btn {
    background-color: var(--primary-orange);
}

.earthquake-btn {
    background-color: #504c4c;
}

.catdrop-btn {
    background-color: var(--primary-blue);
}

.arrow-right {
    position: absolute;
    margin-top: 1rem;
    height: 3rem;
    width: 18rem;
}

.beta-text-label {
    visibility: hidden;
}

@media (min-width: 101px) and (max-width:600px) {

    .beta-text-label {
        background-color: white;
        border: 1px solid #D7664A;
        font-weight: 100;
        font-size: 3rem;
        color: #D7664A;
        padding: 0.3rem 0.8rem 0.3rem 0.8rem;
        display: block;
        position: static;
        width: 14rem;
        text-align: center;
        font-style: normal;
        margin-bottom: 1rem;
        letter-spacing: 0.2em;
        visibility: visible;

        /*border radius*/
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;

    }

    

    .hero-title {
        font-size: 14pt;
        font-weight: 600;
        font-style: italic;
        position: absolute;
        left: 4%;
        bottom: 18%;
        text-align: left;
        cursor: pointer;
        width: 50%;
    }

    .more-text {
        font-size: 3rem;
    }

    .banner-img {
        height: 80rem;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #hero1 {
        background-position-y: 15rem;
        background-size: 150%;
        background-color: #A2F9F4;
    }

    #hero2 {
        background-position-y: 10rem;
        background-size: 80rem;
    }

    #hero4 {
        /* height: 65rem; */
        height: 85vh;
        background-image: url("../images/earthquake.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 43%;
        position: relative;
    }

    #hero4 .hero-title {
        width: 70%;
    }

    .more-content-wrapper-hidden {
        visibility: visible;
    }

    .nft-collection {
        width: 100%;
        /* align-items: center; */
        gap: 5rem;
        padding: 7rem 4rem;
    }

    .nft-collection__img {
        width: 33%;
    }

    .nft-collection__desc {
        font-size: 2.3rem;
        max-width: 100%;
    }

    /* .earthquake-collection .nft-collection__img {
        width: 32%;
    } */

    /* .earthquake-collection {
        margin-bottom: 20rem;
    }

    .catdrop-collection {
        padding-top: 17rem;
    }
    
    .nft-collection__info-top {
        margin-bottom: 3rem;
    } */

}