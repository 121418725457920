/* Modal & Popup */

.modal {
    width: 100%;
    height: 100vh;
    background-color: #d7664abf;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.popup {
    margin: auto;
    padding: 3rem 5rem;
    background-color: #ffffffd4;
    box-shadow: 5px 5px 20px #0000001C;
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.error-wrapper, .menu-wrapper{
    text-align: center;
    padding: 3rem;
}

.error-wrapper {
    max-width: 60rem;
}

.menu-wrapper {
    width: 80rem;
}

.menu-link {
    color: var(--primary-grey);
}

.menu-text { 
    text-align: left;
}

.error-text, .menu-text {
    font-size: 2.7rem;
    font-weight: 400;
    color: var(--primary-grey);
    line-height: 1.3;
    margin-bottom: 3rem;
}

.error-btn, .menu-btn {
    background-color: var(--primary-orange);
    color: white;
    display: inline-block;
    width: 30rem;
    font-size: 2.5rem;
    font-weight: 500;
    padding: 1rem 3rem;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
    border: none;
}