#about {
    padding: 5rem 12rem;
    text-align: center;
}

.about-title {
    font-size: 5rem;
    font-weight: 600;
    color: var(--primary-orange);
    margin-bottom: 4rem;
}

.about-video {
    width: 75%;
    aspect-ratio: 16/9;
}

@media (max-width:600px) {
    #about {
        padding: 5rem;
    }
}